import {GetUserRoleInGroup} from "@/service/user";
import {GROUP_ROLE} from "@/constants";
import axios from "axios";

export const GroupMixins = {
    data(){
        return {
            groupId:'',
            groupRole:''
        }
    },
    created() {
        this.groupId = this.$route.params.groupId || '';
        if(!this.groupId){
            this.$router.push('/manager/group')
            return;
        }
        axios.interceptors.request.use(config => {
            config.headers.groupId = this.groupId;
            return config
        }, error => {
            return Promise.reject(error)
        })
        GetUserRoleInGroup(this.groupId).then(e => {
            let data = this.APIParse(e)
            this.groupRole = data;
            this.roleLoadSuccess && this.roleLoadSuccess()
        })
    },
    computed:{
        IS_LEADER: function (){
            // return true
            return this.groupRole == GROUP_ROLE.LEADER;
        },
        IS_MANAGER: function (){
            // return true
            return this.groupRole == GROUP_ROLE.MANAGER;
        },
        IS_USER: function (){
            // return true
            return this.groupRole == GROUP_ROLE.USER;
        }
    }
}