import uniqueId from '@form-create/utils/lib/unique';

const label = '上传文件';
const name = 'x-upload';

export default {
    icon: 'icon-upload',
    label,
    name,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            info: '',
            props: {}
        };
    },
    props() {
        return [];
    }
};